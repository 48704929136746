import { Button } from '@headrace/ui';
import Link from 'next/link';
import type { VFC } from 'react';

interface SeeAllButtonProps {
  url: string;
  text?: string;
}

const SeeAllButton: VFC<SeeAllButtonProps> = (props) => {
  const { url, text = 'See all' } = props;
  return (
    <Link href={url} passHref>
      <a>
        <Button
          className="text-xs font-medium text-gray-700 py-2 px-3"
          buttonType="secondary"
          size="sm"
        >
          {text}
        </Button>
      </a>
    </Link>
  );
};

export default SeeAllButton;
