import type { VFC } from 'react';

import AcceptanceRateWidget from './AcceptanceRateWidget';
import LatestMarketplaceRolesCard from './LatestMarketplaceRolesCard';
import RecentActivityCard from './RecentActivityCard';
import RecrutierStatsCard from './RecrutierStatsCard';

const ViewState: VFC = () => (
  <div className="flex flex-wrap flex-col xl:flex-row gap-6">
    <RecrutierStatsCard className="w-full" />
    <div className="grid grid-cols-3 gap-4">
      <div className="col-span-3 lg:col-span-1 gap-4">
        <AcceptanceRateWidget className="mb-4" />
        <RecentActivityCard className="max-w-full h-fit" />
      </div>
      <LatestMarketplaceRolesCard className="col-span-3 flex-auto lg:col-span-2 overflow-y-auto lg:min-h-[745px] lg:max-h-[990px] lg:rounded-b-lg !bg-gray-50" />
    </div>
  </div>
);

export default ViewState;
