import {
  CandidateActivityHistoryActivityType,
  MarketplaceVisibilityEnum,
  RecentActivityType,
} from '@headrace/types';
import type { Timeline } from '@headrace/ui';
import {
  CardWithHeader,
  EmptyStateIllustration,
  ErrorMessageBox,
  LoadingSpinner,
  SimpleFeed,
} from '@headrace/ui';
import { formatApolloError, formatBounty, formatDate } from '@headrace/utils';
import {
  CheckIcon,
  DocumentTextIcon,
  PauseIcon,
  StarIcon,
  UserIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { CurrencyDollarIcon } from '@heroicons/react/solid';
import { ChatBubblesQuestion } from '@styled-icons/fluentui-system-filled';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { type VFC, useMemo } from 'react';

import { useRecentActivityQuery } from '@/graphql/generated';

interface RecentActivityCardProps {
  className?: string;
}

const RecentActivityCard: VFC<RecentActivityCardProps> = ({ className }) => {
  const { data, loading, error } = useRecentActivityQuery();
  const { push } = useRouter();

  const timeline = useMemo(() => {
    const timelineList: Timeline[] = [];
    if (data?.recentActivity)
      data.recentActivity.forEach((activity) => {
        let timeLineItem: Timeline | null = null;
        const date = formatDate(new Date(activity.date), 'MMM dd');
        const href = `/roles/${activity.roleId}`;
        if (activity.type === RecentActivityType.CANDIDATE) {
          const candidateName = activity.candidateName || '';

          switch (activity.candidateActivity) {
            case CandidateActivityHistoryActivityType.SUBMITTED:
              timeLineItem = {
                id: activity.id,
                content: `${candidateName} submitted to`,
                target: `${activity.employerName} / ${activity.roleName}`,
                href,
                date,
                icon: UserIcon,
                iconBackground: 'bg-gray-400',
              };
              break;
            case CandidateActivityHistoryActivityType.REJECTED:
              timeLineItem = {
                id: activity.id,
                content: `${candidateName} rejected for`,
                target: `${activity.employerName} / ${activity.roleName}`,
                href,
                date,
                icon: XIcon,
                iconBackground: 'bg-red-500',
              };
              break;
            case CandidateActivityHistoryActivityType.IN_PROCESS:
              timeLineItem = {
                id: activity.id,
                content: `${candidateName} accepted for`,
                target: `${activity.employerName} / ${activity.roleName}`,
                href,
                date,
                icon: CheckIcon,
                iconBackground: 'bg-green-500',
              };
              break;
            case CandidateActivityHistoryActivityType.OFFER_ACCEPTED:
              timeLineItem = {
                id: activity.id,
                content: `${candidateName} hired for`,
                target: `${activity.employerName} / ${activity.roleName}`,
                href,
                date,
                icon: StarIcon,
                iconBackground: 'bg-headraceYellow-700',
              };
              break;
            default:
              break;
          }
        }
        if (activity.type === RecentActivityType.Q_AND_A_RESPONSE) {
          timeLineItem = {
            id: activity.id,
            content: 'New Q&A response for',
            target: `${activity.employerName} / ${activity.roleName}`,
            href: `/roles/${activity.roleId}/messaging`,
            date,
            icon: ChatBubblesQuestion,
            iconBackground: 'bg-blue-500',
          };
        }
        if (activity.type === RecentActivityType.ROLE_NOTE) {
          timeLineItem = {
            id: activity.id,
            content: 'Search notes updated for',
            target: `${activity.employerName} / ${activity.roleName}`,
            href: `/roles/${activity.roleId}/messaging`,
            date,
            icon: DocumentTextIcon,
            iconBackground: 'bg-headraceYellow-700',
          };
        }
        if (activity.type === RecentActivityType.PRICE_CHANGE) {
          const rolePriceChange = activity.rolePrice;
          const rolePriceChangeFormatted = rolePriceChange
            ? formatBounty(rolePriceChange.amount, rolePriceChange.feeType)
            : '';
          const content =
            activity.roleType === MarketplaceVisibilityEnum.INVITE
              ? 'Placement fee for'
              : 'Reward for';
          const price = `changed to ${rolePriceChangeFormatted}`;
          timeLineItem = {
            id: activity.id,
            content,
            target: `${activity.employerName} / ${activity.roleName}`,
            extra: price,
            href,
            date,
            icon: CurrencyDollarIcon,
            iconBackground: 'bg-headraceYellow-700',
          };
        }
        if (activity.type === RecentActivityType.ROLE_SALARY_CHANGE) {
          const content = 'Role salary range updated for';
          const salary = `from ${activity.oldSalaryRange || ''} to ${
            activity.actualSalaryRange || ''
          }`;
          timeLineItem = {
            id: activity.id,
            content,
            target: `${activity.employerName} / ${activity.roleName}`,
            extra: salary,
            href,
            date,
            icon: CurrencyDollarIcon,
            iconBackground: 'bg-headraceYellow-700',
          };
        }
        if (activity.type === RecentActivityType.ROLE_CANCELED) {
          timeLineItem = {
            id: activity.id,
            content: `${activity.roleName} closed by`,
            target: activity.canceledBy
              ? `${activity.canceledBy} / ${activity.employerName}`
              : activity.employerName,
            href: `/roles/${activity.roleId}`,
            date,
            icon: XCircleIcon,
            iconBackground: 'bg-red-500',
          };
        }
        if (activity.type === RecentActivityType.ROLE_PAUSED) {
          timeLineItem = {
            id: activity.id,
            content: `${activity.roleName} paused by`,
            target: activity.pausedBy
              ? `${activity.pausedBy} / ${activity.employerName}`
              : activity.employerName,
            href: `/roles/${activity.roleId}`,
            date,
            icon: PauseIcon,
            iconBackground: 'bg-headraceYellow-700',
          };
        }
        if (timeLineItem) {
          timelineList.push(timeLineItem);
        }
      });

    return timelineList;
  }, [data?.recentActivity]);

  const childrenComponent = useMemo(() => {
    if (loading) {
      return <LoadingSpinner />;
    }
    if (error) {
      return <ErrorMessageBox error={formatApolloError(error)} />;
    }
    if (!timeline?.length) {
      return (
        <EmptyStateIllustration
          image="/illustrations/dashboardEmptyState/latestActivity.svg"
          title="No recent activity"
          description="It looks like there’s no recent activity for your account. Get started today by exploring roles in the HeadRace Marketplace."
          descriptionClassName="w-full"
          imageClassName="w-3/4"
          button={{
            label: 'Go to Marketplace',
            onClick: async (): Promise<void> => {
              await push('/marketplace');
            },
          }}
        />
      );
    }
    return (
      <div
        className={classNames('p-6', {
          'h-[600px] overflow-y-scroll ': timeline?.length > 6,
        })}
      >
        <SimpleFeed timeline={timeline} />
      </div>
    );
  }, [error, loading, push, timeline]);
  return (
    <CardWithHeader
      title="Recent activity"
      className={className}
      childrenClassName="!p-0"
    >
      {childrenComponent}
    </CardWithHeader>
  );
};

export default RecentActivityCard;
