import { formatApolloError } from '@headrace/utils';

import type { CandidateMetricsQuery } from '@/graphql/generated';
import {
  useCandidateMetricsQuery,
  useCountActiveRolesQuery,
} from '@/graphql/generated';

interface RecruiterStatsProps {
  data: {
    activeRolesCount: number;
    candidateMetrics?: CandidateMetricsQuery['candidateMetrics'];
  };
  loading: {
    loadingActiveRolesCount: boolean;
    loadingCandidateMetrics: boolean;
  };
  error: {
    errorActiveRolesCount?: string;
    errorCandidateMetrics?: string;
  };
}

const useRecruiterStats = (): RecruiterStatsProps => {
  const {
    data: countActiveRolesData,
    loading: loadingActiveRolesCount,
    error: errorActiveRolesCount,
  } = useCountActiveRolesQuery();

  const {
    data: candidateMetricsData,
    loading: loadingCandidateMetrics,
    error: errorCandidateMetrics,
  } = useCandidateMetricsQuery();

  return {
    data: {
      activeRolesCount: countActiveRolesData?.countActiveRoles || 0,
      candidateMetrics: candidateMetricsData?.candidateMetrics,
    },
    loading: {
      loadingActiveRolesCount,
      loadingCandidateMetrics,
    },
    error: {
      errorActiveRolesCount:
        errorActiveRolesCount && formatApolloError(errorActiveRolesCount),
      errorCandidateMetrics:
        errorCandidateMetrics && formatApolloError(errorCandidateMetrics),
    },
  };
};

export default useRecruiterStats;
