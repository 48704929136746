import { LoadingSpinner, UserStats } from '@headrace/ui';
import { ExclamationIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';

import useRecruiterStats from '@/lib/hooks/useRecruitertStats';
import { useRecruiter } from '@/lib/RecruiterProvider';

interface RecrutierStatsCardProps {
  className?: string;
}

const RecrutierStatsCard: VFC<RecrutierStatsCardProps> = (props) => {
  const { className } = props;
  const {
    recruiter,
    loading: loadingRecruiter,
    isDemoAccount,
  } = useRecruiter();

  const {
    data: { candidateMetrics, activeRolesCount },
    loading: { loadingCandidateMetrics, loadingActiveRolesCount },
    error: { errorCandidateMetrics, errorActiveRolesCount },
  } = useRecruiterStats();

  const candidatesInReviewCount = isDemoAccount
    ? 3
    : candidateMetrics?.submittedCount || 0;
  const candidatesInProcessCount = isDemoAccount
    ? 4
    : candidateMetrics?.interviewingCount || 0;
  const candidatesHiredCount = isDemoAccount
    ? 2
    : candidateMetrics?.hiredCount || 0;

  const pluralOrSingular = (
    count: number,
    singular: string,
    plural: string
  ): string => (count > 1 ? plural : singular);

  if (loadingRecruiter) {
    return (
      <div className={classNames('bg-white shadow rounded-lg p-12', className)}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!recruiter) {
    return (
      <div
        className={classNames(
          'flex flex-col items-center text-center justify-center bg-white shadow rounded-lg p-12',
          className
        )}
      >
        <ExclamationIcon className="h-12 w-12 text-gray-400" />
        <p className="text-sm text-gray-500">
          No recruiter information found for the current user. You must complete
          registration process.
        </p>
      </div>
    );
  }

  return (
    <UserStats
      className={className}
      userImageSrc={recruiter.user.photoUrl}
      userName={`${recruiter.user.firstName} ${recruiter.user.lastName}`}
      userSubtitle={recruiter.agency.name}
      stats={[
        {
          statTitle: `${activeRolesCount} active ${pluralOrSingular(
            activeRolesCount,
            'role',
            'roles'
          )}`,
          statValue: activeRolesCount,
          statLabel: `active ${pluralOrSingular(
            activeRolesCount,
            'role',
            'roles'
          )}`,
          loading: loadingActiveRolesCount,
          error: errorActiveRolesCount,
        },
        {
          statTitle: `${candidatesInReviewCount} ${pluralOrSingular(
            candidatesInReviewCount,
            'candidate',
            'candidates'
          )} in review`,
          statValue: candidatesInReviewCount,
          statLabel: `${pluralOrSingular(
            candidatesInReviewCount,
            'candidate',
            'candidates'
          )} in review`,
          loading: loadingCandidateMetrics,
          error: errorCandidateMetrics,
        },
        {
          statTitle: `${candidatesInProcessCount} ${pluralOrSingular(
            candidatesInProcessCount,
            'candidate',
            'candidates'
          )} in process`,
          statValue: candidatesInProcessCount,
          statLabel: `${pluralOrSingular(
            candidatesInProcessCount,
            'candidate',
            'candidates'
          )} interviewing`,
          loading: loadingCandidateMetrics,
          error: errorCandidateMetrics,
        },
        {
          statTitle: `${candidatesHiredCount} ${pluralOrSingular(
            candidatesHiredCount,
            'placement',
            'placements'
          )}`,
          statValue: candidatesHiredCount,
          statLabel: pluralOrSingular(
            candidatesHiredCount,
            'placement',
            'placements'
          ),
          loading: loadingCandidateMetrics,
          error: errorCandidateMetrics,
        },
      ]}
    />
  );
};

export default RecrutierStatsCard;
