import classNames from 'classnames';
import type { VFC } from 'react';

import { useAcceptanceRateMetricsQuery } from '@/graphql/generated';

interface SearchAgreementsCardProps {
  className?: string;
}

const AcceptanceRateWidget: VFC<SearchAgreementsCardProps> = ({
  className,
}) => {
  const { data } = useAcceptanceRateMetricsQuery();

  const currentquartile =
    data?.acceptanceRateMetrics.quartileAcceptanceRate || 0;

  const getMessageByAcceptanceRate = (): string => {
    const emptyMessage =
      'Your acceptance rate will show up here once you have 5 candidates reviewed by employers. Note that acceptance rate is an important indicator of employer satisfaction and some employers may choose to manage access to their roles based on this metric.';
    if (currentquartile === 0) {
      return emptyMessage;
    }
    if (currentquartile === 4) {
      return 'Congratulations! Your acceptance rate puts you in at the top of HeadRace recruiters. A higher acceptance rate give you priority access to new roles based on employer preferences. Keep up the great work!';
    }
    if (currentquartile === 3) {
      return 'Acceptance rate is an important indicator of employer satisfaction. A higher acceptance rate give you priority access to new roles based on employer preferences. Keep up the great work!';
    }
    if (currentquartile === 2) {
      return 'Your candidate acceptance rate in the bottom half of recruiters on the HeadRace platform. Remember that acceptance rate is an important indicator of employer satisfaction. A higher acceptance rate give you priority access to new roles based on employer preferences.';
    }
    if (currentquartile === 1) {
      return 'Caution! Your candidate acceptance rate is in the lowest quartile of recruiters on the HeadRace platform. Acceptance rate is an important indicator of employer satisfaction and some employers may choose to limit access to their roles based on this metric.';
    }
    return emptyMessage;
  };

  const getQuartileLabel = (): string => {
    const emptyMessage = 'Calculating ...';
    if (currentquartile === 0) {
      return emptyMessage;
    }
    if (currentquartile === 4) {
      return 'Excellent';
    }
    if (currentquartile === 3) {
      return 'Above Average';
    }
    if (currentquartile === 2) {
      return 'Average';
    }
    if (currentquartile === 1) {
      return 'Below Average';
    }
    return emptyMessage;
  };

  const getQuartileColor = (): string => {
    const emptyMessage = 'bg-gray-200';
    if (currentquartile === 0) {
      return emptyMessage;
    }
    if (currentquartile === 4) {
      return 'bg-green-500';
    }
    if (currentquartile === 3) {
      return 'bg-green-300';
    }
    if (currentquartile === 2) {
      return 'bg-headraceYellow-400';
    }
    if (currentquartile === 1) {
      return 'bg-headraceYellow-700';
    }
    return emptyMessage;
  };

  return (
    <div className={className}>
      <dl>
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <p className="truncate text-sm font-medium text-gray-500">
              Candidate acceptance rate
            </p>
          </dt>
          <dd
            className={classNames(
              'flex  items-center rounded-lg my-4 p-4',
              getQuartileColor()
            )}
          >
            <p className="text-2xl font-semibold text-headraceBlack-600 mr-2">
              {getQuartileLabel()}
            </p>
            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6 h-10">
              <div className="text-sm hidden">
                <a
                  href="#"
                  className="font-medium text-headraceYellow-700 hover:text-headraceYellow-700"
                >
                  Learn more
                </a>
              </div>
            </div>
          </dd>
          <div className="mb-4 text-gray-500 text-sm">
            {getMessageByAcceptanceRate()}
          </div>
        </div>
      </dl>
    </div>
  );
};

export default AcceptanceRateWidget;
