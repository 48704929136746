import { MarketplaceVisibilityEnum, RoleTypeEnum } from '@headrace/types';
import type { ItemsMenu, OverviewListItem } from '@headrace/ui';
import { MenuActions, OverviewList, toastMessage } from '@headrace/ui';
import { formatBounty, formatOrTBD } from '@headrace/utils';
import type { VFC } from 'react';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';

import { useLatestMarketplaceRolesQuery } from '@/graphql/generated';
import { ampli } from '@/lib/ampli';

import SeeAllButton from './SeeAllButton';

interface LatestMarketplaceRolesCardProps {
  className?: string;
}

const LatestMarketplaceRolesCard: VFC<LatestMarketplaceRolesCardProps> = (
  props
) => {
  const { className } = props;
  const { data, loading, error } = useLatestMarketplaceRolesQuery();

  const registerClickEvent = useCallback(() => {
    ampli.roleDashboardRoleClicked({
      app: 'Recruiter',
    });
  }, []);
  const roleList = useMemo<OverviewListItem[]>(() => {
    if (data?.latestMarketplaceRoles?.length) {
      return data.latestMarketplaceRoles.map((role) => {
        let cities = null;
        if (role.cities?.length) {
          cities =
            role?.cities.length > 1
              ? `Multiple`
              : `${role?.cities[0].cityName}`;
        }
        let fee = '';
        let marketplaceVisibility = '';
        const priceFormatted = role.feeType
          ? formatBounty(role.fee, role.feeType)
          : '';
        if (role.marketplaceVisibility === MarketplaceVisibilityEnum.PUBLIC) {
          fee = `${formatOrTBD(priceFormatted)} reward`;
          marketplaceVisibility = 'Public';
        }
        if (role.marketplaceVisibility === MarketplaceVisibilityEnum.INVITE) {
          fee = `${formatOrTBD(priceFormatted)} placement fee`;
          marketplaceVisibility = 'Invite-only';
        }
        const description = `${role.employer.company.name} | ${
          cities ? `${cities} | ` : ''
        }Salary range: ${formatOrTBD(
          role.expectedFirstYearSalaryMin,
          'currency'
        )} - ${formatOrTBD(
          role.expectedFirstYearSalaryMax,
          'currency'
        )} | ${fee} | Visibility: ${marketplaceVisibility}`;
        const optionsMenu: ItemsMenu[] = [
          {
            label: 'View details',
            action: `/marketplace/${role.id}`,
          },
          {
            label: 'Submit candidate',
            action:
              role?.roleType === RoleTypeEnum.MARKETPLACE
                ? (): void => {
                    toast.error(
                      toastMessage({
                        title: '',
                        subtitle: 'Candidate submissions no longer allowed',
                      })
                    );
                  }
                : `/roles/${role.id}/submit-candidate`,
          },
        ];

        return {
          title: role.name,
          description,
          link: `marketplace/${role.id}`,
          tooltip: description,
          actionMenu: <MenuActions options={optionsMenu} />,
          onClick: registerClickEvent,
        };
      });
    }
    return [];
  }, [data, registerClickEvent]);

  return (
    <OverviewList
      className={className}
      title="Latest marketplace roles"
      actionElement={
        <SeeAllButton url="/marketplace" text="View Marketplace" />
      }
      emptyTitle="No marketplace roles available."
      emptyDescription="Check again later."
      items={roleList}
      loading={loading}
      error={error}
      maxItems={10}
      wrapText
    />
  );
};

export default LatestMarketplaceRolesCard;
