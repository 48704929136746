import type { SecondaryNavigationItem } from '@headrace/ui';
import { useSecondaryNav } from '@headrace/ui';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import ViewState from '@/components/dashboard/ViewState';
import { ampli } from '@/lib/ampli';

const InviteEmployerModal = dynamic(
  () => import('@/components/settings/employer-invites/InviteEmployerModal')
);

const Home: NextPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const { setNavigationItems } = useSecondaryNav();

  const openInviteEmployerModal = (): void => {
    ampli.userInviteEmployerClicked();
    setOpenModal(true);
  };

  useEffect(() => {
    const navigationItems: SecondaryNavigationItem[] = [
      {
        type: 'primary',
        label: 'Invite client',
        action: openInviteEmployerModal,
      },
    ];
    setNavigationItems(navigationItems);
  }, [setNavigationItems]);

  return (
    <>
      <ViewState />
      <InviteEmployerModal
        open={openModal}
        closeModal={(): void => setOpenModal(false)}
      />
    </>
  );
};

export default Home;
